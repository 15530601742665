@import "./styles/colors.css";

/* LOGIN */
* {
  box-sizing: border-box;
}


#root {
  height: 100%;
}

#main {
  height: 100%;
  max-height: 100%;
}

html {
  height: 100%;
  max-height: 100%;
  overscroll-behavior:auto!important;
  background-color: var(--color-bg-lower);
  font-size: 16px;
}

.full-height {
  height: 98%;
}

.card-body {
  background-color: inherit;
}

.card {
  background-color: inherit;
}

.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.form-container {
  border: 1px solid aqua;
  border-radius: 20px;
}
.caption {
  font-size: 1.5rem;
  margin: 15px;
  padding: 5px;
}
.login-form {
  padding: 20px;
  margin: 50px;
}
.form-label {
  font-size: 1rem;
  padding: 1px;
  margin: 1px;
}
.form-field {
  padding: 5px;
  width: 300px;
  border: 1px solid var(--color-accent);
  border-radius: 10px;
}
.form-btn {
  float: right;
  width: 100px;
  padding: 5px;
  margin: 10px;
  border: 1px solid aqua;
  border-radius: 10px;
}
.form-btn:hover {
  cursor: pointer;
}
.user-info {
  text-align: center;
  font-size: 2rem;
}
.show {
  display: block;
}
.hide {
  display: none;
}


.language-select {
  float: right;
  /* margin-top: -35px; */
}

.badge {

  margin: 2px;
}

@media only screen and (min-width:320px)  {
  ::-webkit-scrollbar {
    width: 0em;
  }
   
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
   
  ::-webkit-scrollbar-thumb {
    background-color: var(--color-accent-dark);
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }
  body {
    /* color: #fff; */
    height: 99vh;
    overscroll-behavior: auto!important;
    
  }
}
@media (min-width:481px) {
  ::-webkit-scrollbar {
    width: 0em;
  }
   
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
   
  ::-webkit-scrollbar-thumb {
    background-color: var(--color-accent-dark);
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }
  body {
    /* color: #fff; */
    height: 99vh;
    overflow: auto;
  
  }
}

@media (min-width:600px) {
  ::-webkit-scrollbar {
    width: 1em;
  }
   
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
   
  ::-webkit-scrollbar-thumb {
    background-color: var(--color-accent-dark);
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }
  body {
    /* color: #fff; */
    height: 99vh;
    margin: 0!important;
  }
}



.form-control:focus {
  border-color: var(--color-accent);
  box-shadow: 0 0 0 0.2rem var(--color-border-select);
} 


@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html, body {
    height: visible !important;
    overflow: visible !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
  .calendar {
    overflow: visible!important;
    display: block;
  }
  .calendar-cell {
    /* overflow: visible!important; */
    width:20px !important;
    min-width:20px!important ;

  }
}

.calendar-cell {
  /* overflow: visible!important; */
  min-width:80px ;
}

@media print {
  @page { size: auto; scale: 50; }
}

.ripple {
  animation: ripple 0.8s linear infinite;
}
@keyframes ripple {
  0% {
    box-shadow: 0 0 0 1px rgba(0,0,0, 0.2),
                0 0 0 2px rgba(0,0,0, 0.2),
                0 0 0 6px rgba(0,0,0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 2px rgba(0,0,0, 0.2),
                0 0 0 6px rgba(0,0,0, 0.2),
                0 0 0 12px rgba(0,0,0, 0);
  }
}

/* @media print {
  .standard-card-print {
    height: 85mm!important;
    width: 54mm!important;
    font-size: 0.5rem!important;
  }
} */

/* @media print {
  @page {
    margin: 0;
  }
} */