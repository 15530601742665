@import "../styles/colors.css";


.hero { 

    padding:0px;
    margin:0px;
    overflow: hidden;
    text-align: center;

    
}



.hero-logo {
    height: min(35vh,35vw,700px);
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding: 2.5vh;
}

.login-wrapper {
    background-color: var(--color-bg);
    margin: 0;


    min-width: fit-content ;

    border-radius: 10px;
    box-shadow: 0 5px 20px 0 rgb(23 33 46 / 80%);

    position: absolute;
    top: min(600px,45vh);
    width: 70%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    max-width: 1000px;
}

.invalid {
    border:1px solid #ff0000!important;
    box-shadow: 0 0 10px #ff0000;
}

.msal-btn {
    background-color: transparent!important;
    color: var(--color-accent-dark)!important;
    border-color: transparent!important;
    font-size: small!important;
    width: 100%;
}


@media only screen and (min-width:901px) {
    .login-right {
        border-radius: 0 10px 10px 0;
    }
}

@media only screen and (max-width:900px) {
    .login-right {
        border-radius: 0 0 10px 10px;
    }
}