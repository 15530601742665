@import "./styles/colors.css";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: var(--color-bg-low);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


.wrapper {
  padding-left: 20px;
  padding-right: 20px;
  
  transition: margin-left 0.2s!important;
  
  max-width: 1280px; 
	margin: 0 auto !important; 
	float: none !important; 
  overflow: auto;
}

.mobile-wrapper {
  padding-left: 2px;
  padding-right: 2px;
  
  max-width: 1280px; 
	margin: 0 auto !important; 
	float: none !important; 
  overflow: auto;
  height: 100%;
  padding-bottom: 58px!important;

}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.center-nav-logo {
  width: 100%;
  transition: margin 20s;

}

.unselectable-nav-item {
  cursor: default!important;
}

.unselectable-nav-item::after {
  background-color: transparent!important;
  background: transparent!important;
  opacity: 0;
  cursor: default!important;

}


.logoff {
  position: absolute!important;
  bottom: 0!important;
  width: 100%;
}

.spinner-wraper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


.page-title {
  text-align: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  display: inline-block!important;
}

.back-arrow {
  font-size: 4.5em;
  color: var(--color-accent);
  float: left;
}

.back-arrow:hover {
  color: var(--color-accent-dark)!important;
  cursor: pointer;
}

.btn-secondary:hover {
  background-color: var(--color-accent-dark)!important;
}

.input-logo {
  background-color: var(--color-accent)!important;
  color: var(--font-color)!important;
}

.full-page {
  display: flex;
  flex-direction: column;
}

.unselectable-nav-item {
  cursor: default!important;
}

.unselectable-nav-item::after {
  background-color: transparent!important;
  background: transparent!important;
  opacity: 0;
  cursor: default!important;

}

.selected-nav {
  background: #000;
  opacity: 0.2;
  cursor: default!important;
}

.lexicon-wrapper {
  margin: 1em;
  max-height: 99%;
}


@media only screen and (max-width:600px) {
  .lexicon-wrapper {
    margin: 0em;
  }
}

.hotspot:hover {
  border: 1px solid black;
  cursor: pointer!important;
}