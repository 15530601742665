.full-page .search-bar {
    /* border: 2px solid var(--color-primary-border); */
    border-radius: 0.25rem;
    margin: 0px auto;
    /* background-color: var(--color-bg-higher); */
    padding: 0.25rem;
    
}

.full-page .search-input {
    background-color: var(--color-bg);
}

.search-bar {
    display: inline-flex;
    width: 100%;
    align-items: center;
}

.list-group-item {
    margin: 1px;
}

.list-group-item:hover {
    color: var(--font-color)!important;
    background-color: var(--color-accent-dark);
    cursor:pointer;
}

.plus-button {
    padding: 10px!important;
}
